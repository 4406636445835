import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import SEO from '../components/seo'

import bamboo from '../images/bamboo.jpg'
import fiji from '../images/fiji.jpg'
import lava from '../images/lava.jpg'

const ServicesPage = ({ data }) => (
  <Layout>
    <SEO title="Služby masážního salonu Vimperk" />

    <div className="container">
      <div className="uk-grid uk-child-width-expand@m pb-2">
        <div>
          <h1 className="pb-1">Služby</h1>

          <p>
            Na začátku našeho sezení probereme Vaše potřeby a&nbsp;z&nbsp;více
            než 30&nbsp;procedur vytvoříme kombinaci právě pro&nbsp;Vás.
            <br />
            <br />
            Díky své dvacetileté praxi Vám sestavím masáž přesně podle
            Vašeho životního stylu a&nbsp;případně podle Vašich zdravotních obtíží.
            <br />
            <br />
            Doporučujeme vyhradit&nbsp;si čas aspoň na&nbsp;hodinovou masáž.
          </p>
        </div>
        <div>
          <Img fluid={data.file.childImageSharp.fluid} objectFit="cover" />
        </div>
      </div>

      <div className="uk-grid uk-child-width-expand@s uk-text-center">
        <Link to="/sluzby/lecebne-masaze">
          <div class="uk-card uk-card-default">
            <div class="uk-card-media-top">
              <img src={lava} alt="" />
            </div>
            <div class="uk-card-body">
              <h3 class="uk-card-titl">Léčebné masáže</h3>
              <p>
                Bolavá záda, unavené nohy, stuhlá&nbsp;šíje. To&nbsp;je jen
                několik málo bolestí, od&nbsp;kterých Vám mohu ulevit.
              </p>
            </div>
          </div>
        </Link>
        <Link to="/sluzby/kombinovane-masaze">
          <div class="uk-card uk-card-default">
            <div class="uk-card-media-top">
              <img src={bamboo} alt="" />
            </div>
            <div class="uk-card-body">
              <h3 class="uk-card-titl">Kombinované masáže</h3>
              <p>
                Uvolňující masáže v&nbsp;kombinaci s&nbsp;relaxačními
                technikami.
              </p>
            </div>
          </div>
        </Link>
        <Link to="/sluzby/luxusni-procedury">
          <div class="uk-card uk-card-default">
            <div class="uk-card-media-top">
              <img src={fiji} alt="" />
            </div>
            <div class="uk-card-body">
              <h3 class="uk-card-titl">Luxusní procedury</h3>
              <p>Dopřejte&nbsp;si maximální relaxaci.</p>
            </div>
          </div>
        </Link>
      </div>

      <h2>Dárkové poukazy</h2>

      <p>
        Dopřejte i rodině a přátelům profesionální masáž.
        <br />
        Vystavím Vám poukaz na procedury v jakékoli hodnotě.
      </p>

      <a className="btn-cta" href="tel:+420776009508">
        Volejte 776 009 508
      </a>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "salon.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default ServicesPage
